class Single{
	constructor() {
		this.data = {
            groups: document.querySelectorAll('.wp-block-columns'),
            imgs: document.querySelectorAll('.content img')
		};
		this.init();   
	}
	init() {
        this.modalInit();
        if(window.innerWidth >= 768){
            this.data.groups.forEach(group => {
                
                if(group.querySelector('.sidebar-ad')){
                    group.querySelector('.sidebar-ad').style.position = 'sticky';
                    group.querySelector('.sidebar-ad').style.top = '85px';    
                }                
            });
        }
	}
    modalInit (){
        const that = this;
        if(document.querySelector('.modal')){
            that.data.imgs.forEach(img => {
                img.addEventListener('click', that.showModal)
            });        
            document.querySelector('.modal .close').addEventListener('click', this.hideModal);
        }        
    } 

    showModal(){
        const that = this;
        let src = event.target.getAttribute('src');
        document.querySelector('.modal img').setAttribute('src', src);
        document.querySelector('.modal').classList.add('active');        
    }

    hideModal(){
        document.querySelector('.modal img').setAttribute('src', '');
        document.querySelector('.modal').classList.remove('active');
    }

}
export default Single;