class GlobalHeader{
	constructor() { 
		this.data = {
            headerTrigger: null
		};
		this.init();
        
	}
  
	init() {         		
    this.manageMobileMenu();
    if(document.querySelector('body').classList.contains('is-mobile'))
      this.mobileHeaderBehavior();
    if(document.querySelector('body').classList.contains('is-desktop'))
		  this.desktopHeaderBehaviour();
	}    

    desktopHeaderBehaviour(){		
		const that = this;
		//DESKTOP HEADER AD MANAGEMENT
		pubg.queue.push(function () {
			pubg.adManager.on('ad.rendered.after', (event) => {
				if(event.data.slotId.includes('pubg-jr2-x6j')){
					document.querySelector('.main-header').style.top = '250px';
					document.querySelector('.header-ad-wrapper').style.top = '0px';
					document.querySelector('.header-ad-wrapper').style.position = 'sticky';
          setTimeout(() => {
						document.querySelector('.main-header').style.top = '0px';
						document.querySelector('.header-ad-wrapper').style.top = '-100%';
						document.querySelector('.header-ad-wrapper').style.position = 'sticky';
						setTimeout(() => {
							document.querySelector('.header-ad-wrapper').style.position = 'relative';
						}, 300);                    
          }, 3000);
					 
				}
			});	
		})
	}
	mobileHeaderBehavior() {
		const that = this;	

    var lastScrollTop = 0;
    window.addEventListener("scroll", function(){      
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if(!this.document.querySelector('.nav-mobile').classList.contains('active') && !document.querySelector('.header-ad-wrapper-mobile').classList.contains('open')){
        if (st > lastScrollTop) {
          document.querySelector('.header-ad-wrapper-mobile').style.top = '0px';
          // document.querySelector('.header-ad-wrapper-mobile').style.position = 'relative';
        } else if (st < lastScrollTop) {                    
            document.querySelector('.header-ad-wrapper-mobile').style.top = '-62px';
            // document.querySelector('.header-ad-wrapper-mobile').style.position = 'sticky';            
        }
        lastScrollTop = st <= 0 ? 0 : st;
      }      
    }, false);

    pubg.queue.push(function () {      
			pubg.adManager.on('ad.rendered.after', (event) => {
				if(event.data.slotId.includes('pubg-fyw-qhi')){                    
					document.querySelector('.main-header').style.top = '60px';
					document.querySelector('.header-ad-wrapper-mobile').style.top = '0px';
					document.querySelector('.header-ad-wrapper-mobile').style.position = 'sticky';
          document.querySelector('.header-ad-wrapper-mobile').classList.add('open');
          setTimeout(() => {
						document.querySelector('.main-header').style.top = '0px';
						document.querySelector('.header-ad-wrapper-mobile').style.top = '-62px';
						document.querySelector('.header-ad-wrapper-mobile').style.position = 'sticky';
						setTimeout(() => {							
              document.querySelector('.header-ad-wrapper-mobile').classList.remove('open');
						}, 300);                    
          }, 3000);
					 
				}
			});	
		})	
	}

  manageMobileMenu(){      
    const that = this;
    document.querySelector('.burguer').addEventListener('click', () => {
        
        if(document.querySelector('#menu-toggle').checked){
            document.querySelector('.nav-mobile').classList.add('active')                
        }
        else{
            document.querySelector('.nav-mobile').classList.remove('active')
        }
    })
  }

}


export default GlobalHeader;