class PostList{
	constructor() { 		
		this.init();   
	}
	init() {
    if(window.innerWidth >= 768){          
      document.querySelectorAll('.sidebar-ad').forEach(element => {          
        element.style.position = 'sticky';
        element.style.top = '85px';
      });
    }         
        
	}    

}


export default PostList;